<template>
	<div id="partners">
		<banner :picS="bannerS" type="video"></banner>
		<div class="teamTitle">
			<titleOne id="team_1" en='partners' title="ビジネスパートナー"></titleOne>
		</div>
		<div class="teamBox">
			<div class="widthBox">
				<teamItem pagetype="partners" :list="list"/>
			</div>
			<el-pagination v-if="total>10" :page-size="pageSize" :current-page="page" @current-change="joinChange" layout="prev, pager, next" :total="total"></el-pagination>
		</div>
	</div>
</template>

<script>
	import banner from '@/components/banner.vue'
	import titleOne from '@/components/titleOne.vue'
	import teamItem from '@/components/teamItem.vue'
	export default {
		name: 'partners',
		components: {
			banner,
			titleOne,
			teamItem
		},
		computed: {
			bannerS() {
				return this.$store.state.bannerS
			},
		},
		data() {
			return {
				page:1,
				pageSize:10,
				list:[],
				total:0
			}
		},
		created() {
			this.getList()
		},
		mounted() {
			this.$nextTick(function(){
				if(this.$route.query.to != undefined || this.$route.query.to != null){
					setTimeout(()=>{
						document.getElementById(this.$route.query.to).scrollIntoView()
					},500)
				}else{
					document.body.scrollIntoView()
				}
			})
		},
		methods: {
			getList(){
				this.$api.get('home/index/partners',{
					page:this.page,
					pageSize:this.pageSize
				}).then(res=>{
					this.list = res.data.list
					this.total = res.data.total
				})
			},
			joinChange(e){
				this.page = e
				this.getJoin()
			},
		}
	}
</script>

<style lang="scss">
	#partners{
		.teamTitle{
			padding:60px 0;
		}
		.el-pagination{
			margin:50px auto 0;
			text-align: center;
			button{
				line-height:40px;
				height:40px;
				padding:0 8px;
				font-weight: 500;
				background-color: rgba($color: #1D2A55, $alpha:1);
				color: #FFF;
				i{
					font-size:24px;
				}
				&:disabled {
				    color: #1D2A55;
				    background-color: rgba($color: #1D2A55, $alpha:0.1);
				    cursor: not-allowed;
				}
			}
			li{
				line-height:40px;
				height:40px;
				padding:0 8px;
				font-size:24px;
				margin:0 5px;
				font-weight: 500;
				color:rgba($color: #1D2A55, $alpha: 0.4);
				&.active{
					color:rgba($color: #1D2A55, $alpha: 1);
				}
			}
		}
		.teamBox{
			padding:60px 0;
			// background:rgba($color: #1D2A55, $alpha: 0.05);
		}
	}
	@media screen  and(max-width: 1200px) {
		#partners{
			.teamBox{
				.widthBox{
					padding: 0 16px;
					width: 100% !important;
					box-sizing: border-box;
					#teamS{
						width: 100%;
					}
				}
			}
		}
	}
	@media screen  and(max-width: 1000px) {
		#partners{
			.teamBox{
				.widthBox{
					#teamS{
						.teamItem{
							width: 100%;
							margin-left: 0px;
						}	
					}
				}
			}
		}
	}
	@media screen  and(max-width: 768px) {
		#partners{
			.teamBox{
				background: #Fff;
				padding-top: 0px;
				padding-bottom: 0px;
				.widthBox{
					#teamS{
						.teamItem{
							min-height: 200px;
							margin-bottom: 20px;
							box-shadow: none;
							.pic{
								width: 50%;
							}
							.cont{
								background: #F5F5F5;
								width: 50%;
								.name{
									color: #3E404D;
									font-size: 15px;
								}
								.label{
									margin-top: 14px;
									div{
										font-size: 14px;
										line-height: 14px;
										color: #3E404D;
										margin: 2px 0;
										line-break: anywhere;
									}
								}
								.info{
									color: #3E404D;
									font-size: 12px;
									margin-top: 16px;
									padding-top: 16px;
								}
							}
						}	
					}
				}
			}
		}
	}
</style>
